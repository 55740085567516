$phone: 600px;
$tablet: 1023px;
$dekstop: 1024px;

////components

.logo-header {
  width: 150px;
  @media screen and (max-width: $phone) {
    width: 90px;
  }
}

.btn-donasi-header {
  @media screen and (max-width: $phone) {
    width: 80px;
  }
}

.nav {
  color: #000000;
  text-decoration: none;
  margin-right: 15px;
  font-weight: 500;
}

.nav-white {
  color: white;
  text-decoration: none;
  margin-right: 15px;
  font-weight: 500;
}

.input {
  @media screen and (max-width: $phone) {
    font-size: 14px !important;
  }
}

.nav-selected {
  color: #2eb054;
  font-weight: 500;
  text-decoration: none;
}

.btn-ubah-status.btn-ubah-status {
  width: 100px;
}

.menu-ubah {
  margin-top: 5px;
}

.bottom-nav {
  // width: 100%;
  position: fixed;
  // position: sticky;
  bottom: 0;
  z-index: 200;
}

.img-modal {
  width: 100%;
  border-radius: 6px;
}

.modal-donasi {
  .MuiPaper-root.MuiPaper-root {
    background-color: unset;
  }
  .MuiPaper-elevation24.MuiPaper-elevation24 {
    box-shadow: none;
  }
}

.btn-mdonasi.btn-mdonasi {
  text-transform: none;
  width: 230px;
  margin-top: 12px;
  font-size: 22px;
  @media screen and (max-width: $phone) {
    width: 150px;
    margin-top: 8px;
    font-size: 14px;
  }
}

.con-card-berita {
  @media screen and (max-width: $phone) {
    display: flex;
  }
}

.con-card-download {
  @media screen and (max-width: $phone) {
    display: flex;
  }
}

.media-berita-res {
  width: 100%;
  height: 180px;
  @media screen and (max-width: $phone) {
    width: 50%;
    height: auto;
  }
}

.media-download {
  width: 100%;
  height: 300px;
  @media screen and (max-width: $phone) {
    height: 150px;
  }
}

.media-gal {
  width: 100%;
  height: 20vw;
  @media screen and (max-width: $phone) {
    height: 30vw;
  }
}

.media-gal:hover {
  transform: none !important;
}

.lainkali {
  background-color: rgb(252, 172, 25) !important;
}

.div-btn-modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 15px;
  align-items: center;
  width: 100%;
}

.div-web-dash {
  height: 30px;
  border-radius: 30px;
  background: rgb(75, 240, 37);
  background: linear-gradient(
    90deg,
    rgba(75, 240, 37, 0.8578781854538691) 0%,
    rgba(242, 255, 240, 1) 100%
  );
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  font-size: 18px;

  span {
    font-weight: 500;
  }
}

.img-gallery {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.detail-img {
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.49);
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  width: calc(100% - 24px);
  padding: 12px;
  font-weight: 500;
  font-size: 12px;
  color: white;
}

.img-topmenu {
  width: 100%;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}

.div-topmenu {
  margin: 20px;
  @media screen and (max-width: $phone) {
    margin-top: 20px;
  }
}

.detail-artikel {
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.49);
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  width: calc(100% - 32px);
  padding: 16px;
  font-weight: 500;
  font-size: 18px;
  color: white;
  height: 40px;
  @media screen and (max-width: $phone) {
    font-size: 16px;
    font-weight: 400;
  }
}

.bg-footer {
  // background-image: url("../Images/bg-footer.svg");
  background-color: #0094BB;
  background-size: cover;
  background-position: center;
  min-height: 312px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  @media screen and (min-width: $phone) and (max-width: $tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 14px;
  }
  @media screen and (max-width: $phone) {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
}

.title-page {
  background-image: url("../Images//bg-title.svg");
  height: 311px;
  width: 100%;
  color: white;
  font-size: 64px;
  line-height: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  @media screen and (max-width: $phone) {
    height: 100px;
    font-size: 30px;
  }
}

.card-program-root {
  cursor: pointer;
}

.img-card {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.logo-tentang {
  width: 400px;
  @media screen and (max-width: $phone) {
    width: 150px;
  }
}

.img-label-tentang {
  width: 150px;
  margin-top: 20px;
  margin-bottom: 16px;
  @media screen and (max-width: $phone) {
    width: 100px;
    margin-bottom: 10px;
    margin-top: 16px;
  }
}

.txt-tntang-top {
  margin-top: 40px;
  @media screen and (max-width: $phone) {
    font-size: 12px !important;
    margin-top: 20px;
  }
}

.txt-tentang {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: $phone) {
    font-weight: 400;
    font-size: 13px;
  }
}

.btn-back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #2eb054;
  width: 70px;
  margin-bottom: 32px;
}

.title-donasi {
  padding: 20px;
  font-weight: bold;
  font-size: 36px;
  line-height: 25px;
  color: #3aaf4d;
  @media screen and (max-width: $phone) {
    font-size: 24px;
  }
}

.title-admin {
  padding: 20px;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  color: #3aaf4d;
}

.div-form {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #081f32;
  padding: 20px;
  @media screen and (max-width: $phone) {
    font-size: 14px;
    line-height: 16px;
  }
}

.mob-col {
  @media screen and (max-width: $phone) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.label-div {
  width: 215px;
  min-width: 215px;
  @media screen and (max-width: $phone) {
    width: fit-content;
    min-width: none;
  }
}

.input-div {
  width: calc(100% - 215px);
  @media screen and (max-width: $phone) {
    margin-top: 10px;
    width: 100%;
  }
}

.btn-pembayaran {
  margin-left: 235px;
  padding-bottom: 50px;
  @media screen and (max-width: $phone) {
    margin-left: 20px;
    padding-bottom: 30px;
  }
}

.div-multiline {
  align-items: flex-start !important;
  .label-div {
    margin-top: 10px;
  }
}

.div-submit {
  margin-left: 215px;
  padding-top: 20px;
  display: flex;
}

.btn-input {
  width: 100px;
  margin-right: 12px;
}

.a-medsos {
  color: white;
  text-decoration: none;
}

.txt-min {
  font-size: 14px;
  color: #999999;
  margin-top: 8px;
}

.txt-title-landing {
  font-weight: bold;
  font-size: 48px;
  margin-top: 70px;
  margin-bottom: 30px;
  color: #2eb054;
  @media screen and (max-width: $phone) {
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 10px;
  }
}

.div-header {
  margin-bottom: 90px;
  @media screen and (max-width: $phone) {
    margin-bottom: 50px;
  }
}

.img-kemitraan {
  position: relative;
  height: 400px;
  @media screen and (max-width: $phone) {
    height: 200px;
  }
}

.div-kemitraan {
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  padding: 20px;
  font-size: 30px;
  font-weight: 500;
  color: white;
  align-items: center;
  justify-content: center;
  // cursor: pointer;
  display: flex;
  flex-direction: column;
}

.wi95 {
  width: 95% !important;
}

.txt-berita-title {
  font-size: 16px;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @media screen and (max-width: $phone) {
    font-size: 14px;
    line-height: 16px;
  }
}

.txt-berita-date {
  font-size: 12px;
  color: #999999;
}

.card-berita-content {
  @media screen and (max-width: $phone) {
    width: 50%;
  }
  height: 80px;
}

.card-download-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px !important;
}

.share-btn {
  outline: none !important;
  margin-right: 10px;
}

.img-preview {
  width: 100px;
}

.btn-tab-act.btn-tab-act {
  background-color: #2eb054;
  text-transform: none;
  color: #e7f8ec;
  &:hover {
    background-color: #2eb054;
    text-transform: none;
    color: #e7f8ec;
    padding: 6px 20px;
  }
  padding: 6px 20px;
}

.div-media {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  max-height: 58vh;
}

.div-media::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.con-dropzone {
  height: 450px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #2eb054;
}

.card-media {
  width: 136px;
  height: 130px;
  cursor: pointer;
}

.card-media-selected {
  width: 136px;
  height: 130px;
  cursor: pointer;
  box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 7px #2eb054;
}

.btn-tab-non.btn-tab-non {
  text-transform: none;
  color: #3aaf4d;
  background-color: none;
  padding: 6px 20px;
}

.div-media-title-program {
  .title-program {
    color: #2eb054;
    margin: 12px;
    height: 60px;
  }
  .nilai-donasi-card {
    margin-top: 12px;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
  }
  .MuiCardMedia-root {
    height: 200px;
  }
  @media screen and (max-width: $phone) {
    display: flex;
    padding: 10px;
    .title-program {
      margin: 0px;
    }
    .nilai-donasi-card {
      font-size: 12px;
    }
    .MuiCardMedia-root {
      width: 50%;
      min-width: 50%;
      height: 100px !important;
      border-radius: 8px;
      margin-right: 10px;
    }
  }
}

.program-content {
  padding: 12px;
}

.div-img-topmenu {
  cursor: pointer;
  padding: 0px 20px !important;
  @media screen and (max-width: $phone) {
    padding: 8px !important;
  }
}

.div-root-PM {
  position: relative;
  padding-bottom: 90px;
  @media screen and (max-width: $phone) {
    padding-bottom: 50px;
  }
}

.root-PM {
  position: absolute;
  top: -70px;
  width: calc(100% - 40px);

  @media screen and (max-width: $phone) {
    top: -40px;
    width: calc(100% - 10px);
  }
}

.select-penerima{
  background-color: #fff;
  width: 150px;
  @media screen and (max-width: $phone) {
    font-size: small;
    width: 90px;
  }
}

.box-PM {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50px;
  background-color: #0094BB;
  color: white;
  padding: 20px;
  @media screen and (max-width: $phone) {
    padding: 10px;
    border-radius: 20px;
  }
}

.title-topmenu {
  font-size: 18px;
  color: #2eb054;
  text-align: center;
  font-weight: 500;
  @media screen and (max-width: $phone) {
    font-size: 12px;
  }
}

.con-grid-PM {
  text-align: center;
}

.label-pm {
  @media screen and (max-width: $phone) {
    font-size: 9px;
  }
}

.value-PM {
  font-size: 30px;
  @media screen and (max-width: $phone) {
    font-size: 15px;
  }
}

.img-rkening {
  width: 50px;
  margin-right: 12px;
}

.no-rkening {
  font-size: 20px;
  font-weight: 600px;
  @media screen and (max-width: $phone) {
    font-size: 18px;
    font-weight: 500px;
  }
}

.nama-rkening {
  font-size: 16px;
  @media screen and (max-width: $phone) {
    font-size: 13px;
  }
}

.img-detail-program {
  height: 500px;
  // height: 100%;
  // width: 100%;
  @media screen and (max-width: $phone) {
    height: 200px;
  }
}

.nominal {
  margin-top: 8px;
  font-size: 24px;
  @media screen and (max-width: $phone) {
    font-size: 18px;
  }
}

.misi-div {
  margin-bottom: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}

.ic-misi {
  width: 100px;
  @media screen and (max-width: $phone) {
    width: 60px;
  }
}

.btn-download.btn-download {
  text-transform: none;
  border-radius: 16px;
}

.selected {
  position: absolute;
  right: 5px;
  top: 0;
  background: white;
  border-radius: 50%;
}

.medsos {
  @media screen and (max-width: $phone) {
    display: flex;
    justify-content: space-around;
  }
}

.div-img-input {
  border: 1px dashed rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.img-input {
  width: 60%;
}

.div-rte {
  min-height: 200px;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  width: calc(100% - 215px);
  padding-left: 10px;
}

.div-listmedia {
  width: 1256px;
  @media screen and (max-width: $dekstop) {
    width: 100%;
  }
}
////components-end

////custom

.c999999 {
  color: #999999;
}

.box-click {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize !important;
}

.no-style {
  text-decoration: none;
}

.relative {
  position: relative;
}

.p20 {
  padding: 20px;
}

.w100 {
  width: 100%;
}

.flex-end {
  justify-content: flex-end;
}

.c-white {
  color: white;
}

.mt25 {
  margin-top: 25px;
}

.flex {
  display: flex;
}

.mt12 {
  margin-top: 12px;
}

.mt16 {
  margin-top: 16px;
}

.mt50 {
  margin-top: 50px;
}

.mY50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.flex-center {
  align-items: center;
}

.fw700 {
  font-weight: 700 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.h75 {
  height: 75px;
}

.fcenter {
  text-align: center;
}

.div-card-program {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mt10 {
  margin-top: 10px;
}

.ml10 {
  margin-left: 10px !important;
}

.mt20 {
  margin-top: 20px;
}

.mt50 {
  margin-top: 50px;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px;
}

.between {
  justify-content: space-between;
}

////custom-end

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTypography-h6 {
  line-height: 1.25rem !important;
}

////progress
.MuiLinearProgress-root {
  height: 10px !important;
  border-radius: 4px !important;
}

///slider

.slick-slide {
  padding-bottom: 10px !important;
}

.slick-prev {
  left: -20px !important;
  z-index: 200;
}

.slick-next {
  right: 0px !important;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  color: #2eb054;
  font-size: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px -4px 20px rgba(173, 173, 173, 0.25);
}

.arrow-slide {
  font-size: 40px !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #e7f8ec !important;
  color: #2eb054 !important;
  .MuiListItemIcon-root {
    color: #2eb054 !important;
  }
}

.MuiTableCell-root {
  vertical-align: top !important;
}

.MuiCardMedia-root:hover {
  transform: scale(1.1);
}

.MuiCardMedia-root {
  transition: all 0.5s !important;
}

.slick-list {
  @media screen and (min-width: $phone) {
    padding: 0 20% 0 0 !important;
  }
}

.slick-dots li {
  width: 5px !important;
}

.slick-dots.slick-dots {
  bottom: -12px;
  text-align: left;
}
.MuiBottomNavigation-root.MuiBottomNavigation-root {
  justify-content: space-around;
  width: 100%;
}

.MuiBottomNavigationAction-root.MuiBottomNavigationAction-root {
  padding: 0px;
}

.MuiToolbar-gutters.MuiToolbar-gutters {
  @media screen and (max-width: $phone) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

._1bpcM._1bpcM {
  position: fixed;
  z-index: 200;
  bottom: 10px;
  @media screen and (max-width: $phone) {
    zoom: 0.9;
    bottom: 50px;
  }
}

._2iWL7._2iWL7 {
  position: fixed;
  z-index: 200;
  bottom: 80px;
  @media screen and (max-width: $phone) {
    zoom: 0.7;
    bottom: 160px;
  }
}

.MuiMenuItem-root.MuiMenuItem-root {
  @media screen and (max-width: $phone) {
    min-height: 10px;
    font-size: 12px;
  }
}

.menu-item-table.menu-item-table {
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}

.sum-accord {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.div-img-metode {
  width: 100%;
  cursor: pointer;
  border: 1px solid rgb(104, 202, 104);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  &:hover {
    background-color: rgba(151, 240, 151, 0.651);
  }
}

.div-img-metode-act {
  width: 100%;
  cursor: pointer;
  border: 1px solid rgb(104, 202, 104);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-color: rgba(151, 240, 151, 0.651);
}

.img-metode {
  width: 70%;
}

.donasi-text-pembayaran.donasi-text-pembayaran {
  font-size: 18px;
  padding: 12px;
  @media screen and (max-width: $phone) {
    font-size: 14px;
    padding: 8px;
  }
}

.cara-pembayaran-text.cara-pembayaran-text {
  font-size: 16px;
  @media screen and (max-width: $phone) {
    font-size: 12px;
  }
}

///pageitaion

.txt-pagenation {
  margin-left: 10px;
}

.txt-pagenation-left {
  margin-right: 10px;
}
